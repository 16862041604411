import { ReactElement } from 'react'

type InfoIconProps = {
  color?: string
}

export default function InfoIcon({color}: InfoIconProps): ReactElement {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2751_6058)">
        <path
          d="M10 0.000610352C4.47718 0.000610352 0 4.47779 0 10.0006C0 15.5234 4.47718 20.0006 10 20.0006C15.5228 20.0006 20 15.5237 20 10.0006C20 4.47755 15.5228 0.000610352 10 0.000610352ZM9.95012 15.5928C9.95012 15.5928 9.48612 15.9013 9.16894 15.8999C9.14071 15.9128 9.124 15.9204 9.124 15.9204L9.12471 15.8999C9.05812 15.8968 8.99106 15.8874 8.92353 15.8705L8.82329 15.8458C8.26941 15.7084 7.97624 15.1592 8.11412 14.6056L8.88518 11.4971L9.23318 10.0931C9.55694 8.78955 8.20659 10.3703 7.92965 9.76979C7.74682 9.37285 8.98 8.5399 9.88094 7.9119C9.88094 7.9119 10.3445 7.6039 10.6619 7.60508C10.6904 7.5919 10.7071 7.58461 10.7071 7.58461L10.7059 7.60508C10.7729 7.60814 10.84 7.61755 10.9075 7.63426L11.0078 7.6592C11.5616 7.79661 11.8991 8.35685 11.7616 8.91049L10.9908 12.0192L10.6421 13.4232C10.3188 14.7267 11.6468 13.1399 11.9235 13.7411C12.1064 14.1376 10.8508 14.9648 9.95012 15.5928ZM11.9045 5.87661C11.712 6.6519 10.9275 7.12461 10.1525 6.93214C9.37694 6.73967 8.90423 5.95543 9.09671 5.1799C9.28918 4.40438 10.0736 3.9319 10.8487 4.12437C11.6242 4.31661 12.0967 5.10108 11.9045 5.87661Z"
          fill={color}   //Dynamic color selection
        />
      </g>
      <defs>
        <clipPath id="clip0_2751_6058">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
